<template>
    <div class="trend-item">
        <div class="avatar-wrapper">
            <avatar
                class="user-avatar"
                size="mg"
                :src="trend.posterAvatarUrl"
                :userId="trend.posterId"
                :enableCard="true"
                :enableLink="true"
            ></avatar>
        </div>
        <div class="trend-item-right">
            <div class="trend-item-info">
                <h3>
                    {{trend.posterRealName}}
                    <span>@{{trend.posterNickName}}</span>
                </h3>
                <p class="trend-item-intro">
                    <span v-if="trend.type == 1" class="trend-item-action">推荐了候选人</span>
                    <span v-if="trend.type == 2 || trend.type == 4" class="trend-item-action">接受了候选人</span>
                    <span v-if="trend.type == 3" class="trend-item-action">拒绝了候选人</span>
                    <span v-if="trend.type == 5 || trend.type == 9 || trend.type == 10 || trend.type == 17" class="trend-item-action">将候选人</span>
                    <span v-if="trend.type == 6 || trend.type == 7" class="trend-item-action">为候选人</span>
                    <span v-if="trend.type == 8" class="trend-item-action">反馈了候选人</span>
                    <span v-if="trend.type == 11" class="trend-item-action">淘汰了候选人</span>
                    <span v-if="trend.type == 12" class="trend-item-action">暂停了</span>
                    <span v-if="trend.type == 13" class="trend-item-action">完成了</span>
                    <span v-if="trend.type == 14" class="trend-item-action">重启了</span>
                    <span v-if="trend.type == 15" class="trend-item-action">发布了</span>
                    <span v-if="trend.type == 16" class="trend-item-action">将</span>
                    <span v-if="trend.type == 18" class="trend-item-action">将</span>
                    <span v-if="trend.type == 19" class="trend-item-action">为职位</span>
                    <span v-if="trend.type == 20" class="trend-item-action">打赏了</span>
                    <span v-if="trend.type == 21" class="trend-item-action">广播了</span>
                    <span v-if="trend.type == 22 || trend.type == 23" class="trend-item-action">将职位</span>
                    <span v-if="trend.type >= 29 && trend.type <= 33" class="trend-item-action">操作了候选人</span>

                    <span v-if="trend.type == 19 || trend.type == 20 || trend.type == 21">{{(trend.extensionProperties.amount * 100).toFixed(0)}}元</span>

                    <span class="trend-item-user-name" v-if="trend.type == 20">{{trend.extensionProperties.recommenderRealName}}<i>@{{trend.extensionProperties.recommenderNickname}}</i></span>
                    

                    <a
                        v-if="trend.type != 12 && trend.type != 13 && trend.type != 14 && trend.type != 15 && trend.type != 16 && trend.type != 19 && trend.type != 20 && trend.type != 21 && trend.type != 22 && trend.type != 23"
                        class="trend-item-candidate" 
                        target="_blank"
                    >
                    <!-- :href="trend.extensionProperties.recommendationDetailUrl" -->
                        {{trend.extensionProperties.candidateName}}
                    </a>
                    
                    <span v-if="trend.type == 5" class="trend-item-action">推进到面试阶段</span>
                    <span v-if="trend.type == 6" class="trend-item-action">安排了{{trend.extensionProperties.interviewType}}</span>
                    <span v-if="trend.type == 7" class="trend-item-action">取消了{{trend.extensionProperties.interviewType}}</span>
                    <span v-if="trend.type == 8" class="trend-item-action">{{trend.extensionProperties.interviewType}}的结果</span>
                    <span v-if="trend.type == 8" class="trend-item-action">({{trend.extensionProperties.result}})</span>
                    <span v-if="trend.type == 9" class="trend-item-action">推进到Offer阶段</span>
                    <span v-if="trend.type == 10" class="trend-item-action">推进到入职阶段</span>
                    <span v-if="trend.type == 16" class="trend-item-action">移交给</span>
                    <span v-if="trend.type == 17" class="trend-item-action">筛选通过了候选人</span>
                    <span v-if="trend.type == 19" class="trend-item-action">充值红包</span>
                    <span v-if="trend.type == 22" class="trend-item-action">的状态修改为不紧急</span>
                    <span v-if="trend.type == 23" class="trend-item-action">的状态修改为紧急</span>

                    <span class="trend-item-job" v-if="trend.type <= 23">
                        <a 
                            v-if="trend.extensionProperties.jobDetailUrl || trend.extensionProperties.jobUrl" 
                            :href="trend.extensionProperties.jobDetailUrl || trend.extensionProperties.jobUrl"
                            target="_blank"
                        >{{trend.extensionProperties.jobName}}</a>
                        <span v-else>{{trend.extensionProperties.jobName}}</span>
                        &ensp;
                        <i>[ {{trend.extensionProperties.jobCompany}}-{{trend.extensionProperties.jobArea}} ]</i>
                    </span>

                    <span v-if="trend.type == 16" class="trend-item-anchor">{{trend.extensionProperties.to}}</span>

                </p>
                <p class="trend-item-extra" v-if="trend.type == 3 && (trend.extensionProperties.rejectedDetail || trendextensionProperties.rejectedReason)">
                    <span v-if="trend.extensionProperties.rejectedReason">原因: {{trend.extensionProperties.rejectedReason}}</span>
                    <br v-if="trend.extensionProperties.rejectedReason && trend.extensionProperties.rejectedDetail"/>
                    <span v-if="extensionProperties.rejectedDetail">明细: {{trend.extensionProperties.rejectedDetail}}</span>
                </p>
                <p class="dynamic-item-extra" v-if="trend.type == 8 && trend.extensionProperties.evaluation">评价：{{trend.extensionProperties.evaluation}}</p>
                <p class="dynamic-item-extra" v-if="trend.type == 11 && trend.extensionProperties.obsoletedReason">
                    <span>原因：{{trend.extensionProperties.obsoletedReason}}</span>
                    <el-tooltip 
                        v-if="trend.extensionProperties.obsoletedDetail" 
                        effect="light" 
                        :content="trend.extensionProperties.obsoletedDetail" 
                        placement="right"
                    >
                        <i class="fa fa-commenting-o"></i>
                    </el-tooltip>
                </p>
                <p class="dynamic-item-extra" v-if="trend.type == 12 && trend.extensionProperties.reason">{{trend.extensionProperties.reason}}</p>
                <p class="dynamic-item-extra" v-if="trend.type == 20 && trend.extensionProperties.remark">{{trend.extensionProperties.remark}}</p>
                <p class="dynamic-item-extra" v-if="trend.type == 21 && trend.extensionProperties.content">{{trend.extensionProperties.content}}</p>
            </div>
        </div>
        <div class="trend-item-time">{{trend.createdString | datetime}}</div>
    </div>
</template>

<script>
import Avatar from '#/component/common/avatar.vue';
export default {
    components: {
        Avatar
    },
    data() {
        return {

        }
    },
    props: {
        trend: {
            type: Object,
            default: function() {
                return {}
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.trend-item {
    display: flex;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid #eee;

    .avatar-wrapper {
        flex-shrink: 0;
        margin-right: 40px;
    }

    &-right {
        display: flex;
        align-items: center;
        flex: 1;
        flex-grow: 1;
    }

    &-time {
        flex-shrink: 0;
    }
}
.trend-item {
    &-info h3 {
        font-size: 16px;
        color: #666;

        span {
            color:#b9b9b9;
        }
    }

    &-job i {
        color: #b9b9b9;
    }

    &-action {
        color: #666;
    }

    &-anchor .anchor-tag {
        color: #333;
    }

    &-meta {
        color: #b9b9b9;
    }

    &-intro,
    &-extra {
        margin-bottom: 0;
    }

    &-extra {
        color: #333;
        margin-top: 10px;

        .fa-commenting-o {
            margin-left: 4px;
            padding-right: 16px;
        }
    }

    &-user-name {
        color: #333;

        i {
            color: #b9b9b9;
        }
    }
}
</style>