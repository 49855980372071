import { render, staticRenderFns } from "./trend-item.vue?vue&type=template&id=5d2b142e&scoped=true&"
import script from "./trend-item.vue?vue&type=script&lang=js&"
export * from "./trend-item.vue?vue&type=script&lang=js&"
import style0 from "./trend-item.vue?vue&type=style&index=0&id=5d2b142e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d2b142e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Work\\CSDN\\Code\\baza-lbd-portal-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5d2b142e')) {
      api.createRecord('5d2b142e', component.options)
    } else {
      api.reload('5d2b142e', component.options)
    }
    module.hot.accept("./trend-item.vue?vue&type=template&id=5d2b142e&scoped=true&", function () {
      api.rerender('5d2b142e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/user-homepage/component/trend-item.vue"
export default component.exports