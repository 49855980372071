var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "table",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.leftLoading,
            expression: "leftLoading",
          },
        ],
        staticClass: "user-homepage-table",
      },
      _vm._l(_vm.tableData, function (bodyData, index) {
        return _c("tbody", { key: "body" + index }, [
          _c(
            "tr",
            _vm._l(bodyData.items, function (item) {
              return _c("td", { key: item.type }, [
                _c("div", { staticClass: "statistics-item" }, [
                  _c("i", {
                    class: `statistics-item-icon statistics-item-icon-${item.type} icon-font icon-${item.icon}`,
                  }),
                  _c("h2", { staticClass: "statistics-item-headline" }, [
                    item.type === "coin"
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.currencyFormat(item.count, "", 0))),
                        ])
                      : _c("span", [_vm._v(_vm._s(item.count))]),
                  ]),
                  _c("p", { staticClass: "statistics-item-name" }, [
                    _c("span", { staticClass: "statistics-item-text" }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                  ]),
                  _c("div", { staticClass: "statistics-item-line" }),
                  _c(
                    "p",
                    { staticClass: "statistics-item-desc" },
                    [
                      _c("span", [_vm._v(_vm._s(item.desc))]),
                      item.type == "approve" && item.desc && item.reason
                        ? _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "top",
                                title: "",
                                width: "200",
                                trigger: "hover",
                                content: item.reason,
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { slot: "reference", type: "text" },
                                  slot: "reference",
                                },
                                [_vm._v("查看拒绝原因")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ])
            }),
            0
          ),
        ])
      }),
      0
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.profileLoading,
            expression: "profileLoading",
          },
        ],
        staticClass: "user-homepage-info",
      },
      [
        _vm.jobTags.length > 0 ||
        _vm.industryTags.length > 0 ||
        _vm.customerTags.length > 0
          ? _c("div", { staticClass: "order-style" }, [
              _c("h3", { staticClass: "user-homepage-info-title" }, [
                _vm._v("做单风格"),
              ]),
              _vm.jobTags.length > 0
                ? _c("div", { staticClass: "order-style-item" }, [
                    _c("p", [_vm._v("擅长职位：")]),
                    _c(
                      "ul",
                      _vm._l(_vm.jobTags, function (jobTag) {
                        return _c("li", { key: jobTag }, [
                          _vm._v(_vm._s(jobTag)),
                        ])
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
              _vm.industryTags.length > 0
                ? _c("div", { staticClass: "order-style-item" }, [
                    _c("p", [_vm._v("擅长领域：")]),
                    _c(
                      "ul",
                      _vm._l(_vm.industryTags, function (industryTag) {
                        return _c("li", { key: industryTag }, [
                          _vm._v(_vm._s(industryTag)),
                        ])
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
              _vm.customerTags.length > 0
                ? _c("div", { staticClass: "order-style-item" }, [
                    _c("p", [_vm._v("拥有资源：")]),
                    _c(
                      "ul",
                      _vm._l(_vm.customerTags, function (customerTag) {
                        return _c("li", { key: customerTag }, [
                          _vm._v(_vm._s(customerTag)),
                        ])
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm.introduction
          ? _c("div", { staticClass: "brief-intro" }, [
              _c("h3", { staticClass: "user-homepage-info-title" }, [
                _vm._v("个人简介"),
              ]),
              _c("p", [_vm._v(_vm._s(_vm.introduction))]),
            ])
          : _vm._e(),
        _vm.trendLoaded
          ? _c("div", { staticClass: "new-trend" }, [
              _c("h3", { staticClass: "user-homepage-info-title" }, [
                _vm._v("最新动态"),
              ]),
              _vm.trendLoaded && _vm.trendTotalCount == 0
                ? _c("div", { staticClass: "new-trend-empty" }, [
                    _vm._v("暂无数据"),
                  ])
                : _vm._e(),
              _vm.trendList.length > 0
                ? _c(
                    "ul",
                    { staticClass: "trend-list" },
                    _vm._l(_vm.trendList, function (trend, index) {
                      return _c("trend-item", {
                        key: index,
                        attrs: { trend: trend },
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _vm.trendTotalCount > 0 &&
              _vm.trendList.length < _vm.trendTotalCount
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.trendMoreLoading,
                          expression: "trendMoreLoading",
                        },
                      ],
                      staticClass: "load-more-trend",
                      on: { click: _vm.loadMoreTrend },
                    },
                    [
                      _vm._v("\n                加载更多\n                "),
                      _c("font-icon", { attrs: { href: "#icon-spread-up" } }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.trendTotalCount > 0 &&
              _vm.trendList.length == _vm.trendTotalCount
                ? _c("div", { staticClass: "has-no-more" }, [
                    _vm._v("\n                没有更多了~\n            "),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }