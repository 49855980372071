<template>
    <div>
        <table class="user-homepage-table" v-loading="leftLoading">
            <tbody v-for="(bodyData, index) in tableData" :key="'body' + index">
                <tr>
                    <td v-for="item in bodyData.items" :key="item.type">
                        <div class="statistics-item">
                            <i :class="`statistics-item-icon statistics-item-icon-${item.type} icon-font icon-${item.icon}`"></i>
                            <h2 class="statistics-item-headline">
                                <span v-if="item.type === 'coin'">{{currencyFormat(item.count, '', 0)}}</span>
                                <span v-else>{{item.count}}</span>
                            </h2>
                            <p class="statistics-item-name">
                                <span class="statistics-item-text">{{item.name}}</span>
                                <!-- <doll class="statistics-item-tooltip" data-bind="visible: tooltip" type="ali" name="information-circle" k-description="tooltip" enable-popover="true"></doll> -->
                            </p>
                            <div class="statistics-item-line"></div>
                            <p class="statistics-item-desc">
                                <span>{{item.desc}}</span>
                                <el-popover
                                    v-if="item.type == 'approve' && item.desc && item.reason"
                                    placement="top"
                                    title=""
                                    width="200"
                                    trigger="hover"
                                    :content="item.reason"
                                >
                                    <el-button slot="reference" type="text">查看拒绝原因</el-button>
                                </el-popover>
                                
                                <!-- <a data-bind="visible: rejectVisibility" href="javascript: void(0);">
                                    <statistics-reason k-reason="reason" text="查看拒绝原因"></statistics-reason>
                                </a> -->
                            </p>
                           
                            <!-- <p 
                                v-show="item.link && (
                                    item.type == 'order' ||
                                    (item.type == 'recommend' && userInfo.firmId == loginUserFirmId)
                                )" 
                                class="statistics-item-link"
                            >
                                <el-button plain type="primary" @click="handleJump(item.link)">查看</el-button>
                            </p> -->
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="user-homepage-info" v-loading="profileLoading">
            <div class="order-style" v-if="jobTags.length > 0 || industryTags.length > 0 || customerTags.length > 0">
                <h3 class="user-homepage-info-title">做单风格</h3>
                <div class="order-style-item" v-if="jobTags.length > 0">
                    <p>擅长职位：</p>
                    <ul>
                        <li v-for="jobTag in jobTags" :key="jobTag">{{jobTag}}</li>
                    </ul>
                </div>
                <div class="order-style-item" v-if="industryTags.length > 0">
                    <p>擅长领域：</p>
                    <ul>
                        <li v-for="industryTag in industryTags" :key="industryTag">{{industryTag}}</li>
                    </ul>
                </div>
                <div class="order-style-item" v-if="customerTags.length > 0">
                    <p>拥有资源：</p>
                    <ul>
                        <li v-for="customerTag in customerTags" :key="customerTag">{{customerTag}}</li>
                    </ul>
                </div>
            </div>
            <div class="brief-intro" v-if="introduction">
                <h3 class="user-homepage-info-title">个人简介</h3>
                <p>{{introduction}}</p>
            </div>
            <div class="new-trend" v-if="trendLoaded">
                <h3 class="user-homepage-info-title">最新动态</h3>
                <div v-if="trendLoaded && trendTotalCount == 0" class="new-trend-empty">暂无数据</div>
                <ul class="trend-list" v-if="trendList.length > 0">
                    <trend-item v-for="(trend, index) in trendList" :key="index" :trend="trend"></trend-item>
                </ul>
                <div 
                    class="load-more-trend" 
                    v-if="trendTotalCount > 0 && trendList.length < trendTotalCount" 
                    @click="loadMoreTrend"
                    v-loading="trendMoreLoading"
                >
                    加载更多
                    <font-icon href="#icon-spread-up"></font-icon>
                </div>
                <div class="has-no-more" v-if="trendTotalCount > 0 && trendList.length == trendTotalCount">
                    没有更多了~
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { user as userUrl } from '#/js/config/api.json';
import defaultData from '../statistics-diagram.json';
import { foredate, currencyFn } from '#/js/util/tools.js';
import TrendItem from '../component/trend-item.vue';
export default {
    components: {
        TrendItem
    },
    data() {
        return {
            loading: false,
            leftLoading: false,
            profileLoading: false,
            tableData: [],
            industryTags: [],
            jobTags: [],
            customerTags: [],
            introduction: '',
            start: 0,
            take: 10,
            trendLoaded: false,
            trendTotalCount: 0,
            trendList: [],
            trendMoreLoading: false,
        }
    },
    props: {
        userId: String,
        userInfo: {
            type: Object,
            default: function() {
                return {}
            }
        },
        hasViewPermission: Boolean
    },
    computed: {
        loginUserFirmId() {
            return this.$store.state.user.userInfo.firmId;
        }
    },
    mounted() {
    },
    methods: {
        currencyFormat(number, currency, decimals) {
            return currencyFn(number, currency, decimals)
        },

        handleJump(link) {
            location.href = link;
        },
        init() {
            this.getUserData();
            this.getUserProfileTags();
            this.getTrendData();
        },
        getUserData() {
            this.leftLoading = true;
            _request({
                url: userUrl.statistics.replace('%p', this.userId),
                method: 'GET'
            }).then(res => {
                let summary = res, userId = this.userId;
                let dynamicData = [
                    {
                        items: [
                            this.pluckOrder(summary, userId),
                            this.pluckRecommendation(summary, userId),
                            this.pluckInterview(summary)
                        ]
                    },
                    {
                        items: [
                            this.pluckOffer(summary),
                            // this.pluckCoin(summary),
                            // this.pluckResume(summary, userId),
                            this.pluckApprove(summary),
                            this.pluckRequest(summary)
                        ]
                    }
                ]
                dynamicData.forEach((row, i) => {
                    row.items.forEach((item, j) => {
                        dynamicData[i].items[j] = Object.assign({}, defaultData[i].items[j], item);
                    })
                })


                this.tableData = dynamicData;
            }).finally(() => {
                this.leftLoading = false;
            })
        },

        getUserProfileTags() {
            this.profileLoading = true;
            _request({
                url: userUrl.user_profile_tags.replace('%p', this.userId),
                method: 'GET'
            }).then(res => {
                this.industryTags = res.industryTags || [];
                this.jobTags = res.jobTags || [];
                this.customerTags = res.customerTags || [];
                this.introduction = res.introduction || '暂无~';
            }).finally(() => {
                this.profileLoading = false;
            })
        },

        getTrendData() {
            _request({
                url: userUrl.trend_url_dynamics.replace('%p', this.userId),
                method: 'GET',
                data: {
                    start: this.start,
                    take: this.take
                }
            }).then(res => {
                this.trendTotalCount = res.total;
                if(this.start == 0) {
                    this.trendList = res.trendItems;
                }else {
                    this.trendList = this.trendList.concat(res.trendItems);
                }
            }).finally(() =>{
                this.trendLoaded = true;
                this.trendMoreLoading = false;
            })
        },

        loadMoreTrend() {
            if(this.trendMoreLoading) {
                return false;
            }
            this.start = this.start + this.take;
            this.trendMoreLoading = true;
            this.getTrendData();
        },

        pluckOrder(summary, userId) {
            const { orders, recruitingOrders } = summary;

            return {
                count: orders,
                desc: recruitingOrders > 0
                    ? `正在做的订单有 ${recruitingOrders} 个`
                    : '暂时没有正在做的订单',
                // link: `/Headhunting/MyCompany.html#/user/${userId}/jobs?Type=Orders`
                link: `/#/user/${userId}/jobs?Type=Orders`
            };
        },

        pluckRecommendation(summary, userId) {
            const { recommendations, recommendationsInOneMonth, recommendOrdersInOneMonth } = summary;

            return {
                count: recommendations,
                desc: recommendationsInOneMonth > 0
                    ? `近 30 天，为 ${recommendOrdersInOneMonth} 个订单产出了 ${recommendationsInOneMonth} 次推荐`
                    : '最近没有产生推荐记录',
                // link: `/Headhunting/MyCompany.html#/user/${userId}/candidates`
                link: `/#/user/${userId}/candidates`
            };
        },

        pluckInterview(summary) {
            const { interviews, lastInterviewTime } = summary;

            return {
                count: interviews,
                desc: lastInterviewTime
                    ? `上一场面试时间是 ${foredate(lastInterviewTime)}`
                    : '暂时没有安排过面试'
            };
        },

        pluckOffer(summary) {
            const { offers, lastOfferTime } = summary;

            return {
                count: offers,
                desc: lastOfferTime
                    ? `上一次出 Offer 是 ${foredate(lastOfferTime)}`
                    : '暂时没有出过 Offer'
            };
        },

        pluckCoin(summary) {
            let { yunBi, lastYunBiCostAmount, lastYunBiCostPurpose } = summary;
            let desc;

            lastYunBiCostAmount = this.currencyFormat(lastYunBiCostAmount, '', 0);

            if (lastYunBiCostPurpose === 'ApplyShare') {
                desc = `上一笔消费 ${lastYunBiCostAmount} 云币用于求分享`;
            } else if (lastYunBiCostPurpose) {
                desc = `上一笔消费 ${lastYunBiCostAmount} 云币兑换 ${lastYunBiCostPurpose}`
            } else {
                desc = '暂时没有云币消费';
            }

            return {
                count: yunBi,
                desc: desc
            };
        },

        pluckResume(summary, userId) {
            const { cloudCandidates, cloudCandidatesPropotion } = summary;

            return {
                count: cloudCandidates,
                desc: `超过了 ${cloudCandidatesPropotion}% 猎头用户`,
                // link: `/Headhunting/MyCompany.html#/user/${userId}/cloudCandidates`
                link: `/#/user/${userId}/cloudCandidates`
            };
        },

        pluckApprove(summary) {
            const { sharesApproved, sharesBeApplied, sharesRejected, sharesApprovedRate, sharesRejectReasons } = summary;

            return {
                count: sharesApproved,
                desc: `收到「求分享」${sharesBeApplied} 次，拒绝 ${sharesRejected} 次，同意率 ${sharesApprovedRate}%`,
                reason: sharesRejectReasons
            };
        },

        pluckRequest(summary) {
            let { sharesApplied, cashIncomeForVendor, yunBiCostForApplyShare } = summary;
            let desc;

            cashIncomeForVendor = this.currencyFormat(cashIncomeForVendor, '', 0);
            yunBiCostForApplyShare = this.currencyFormat(yunBiCostForApplyShare, '', 0);

            if (yunBiCostForApplyShare > 0 && cashIncomeForVendor > 0) {
                desc = `为简历主人带来 ${yunBiCostForApplyShare} 云币收益、${cashIncomeForVendor} 现金分成`;
            } else if (yunBiCostForApplyShare> 0) {
                desc = `为简历主人带来 ${yunBiCostForApplyShare} 云币收益`;
            } else if (cashIncomeForVendor > 0) {
                desc = `为简历主人带来 ${cashIncomeForVendor} 现金分成`;
            } else {
                desc = '暂时没有产生收益';
            }

            return {
                count: sharesApplied,
                desc: desc
            };
        },
    }
}
</script>

<style lang="scss" scoped>
$item-order-background-color: $primary;
$item-recommend-background-color: #d7d84a;
$item-interview-background-color: #8cce94;
$item-offer-background-color: #a3a800;
$item-resume-background-color: #f09994;
$item-approve-background-color: #c38bb7;
$item-request-background-color: #228c55;
.user-homepage {
    &-table {
        border-collapse: collapse;
        border-spacing: 0;
        height: 429px;

        td {
            border: 1px solid #e3e3e3;
            width: 33%;

            .statistics-item {
                position: relative;
                // width: 296px;
                height: 213px;
                padding: 20px 15px 15px;
                text-align: center;
                color: #333;

                &-icon {
                    position: absolute;
                    top: 15px;
                    left: 15px;
                    padding: 5px;
                    color: #fff;
                    border-radius: 50%;

                    &-order {
                        background-color: $item-order-background-color;
                    }

                    &-recommend {
                        background-color: $item-recommend-background-color;
                    }

                    &-interview {
                        background-color: $item-interview-background-color;
                    }

                    &-offer {
                        background-color: $item-offer-background-color;
                    }

                    &-coin {
                        display: inline-block !important;
                        width: 28px;
                        height: 28px;
                        background-repeat: no-repeat;
                        background-image: url('~@src/assets/images/icon/coin.png');
                    }

                    &-resume {
                        background-color: $item-resume-background-color;
                    }

                    &-approve {
                        background-color: $item-approve-background-color;
                    }

                    &-request {
                        background-color: $item-request-background-color;
                    }
                }

                &-headline, &-text {
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                
                &-headline {
                    font-size: 36px;
                    margin-bottom: 0;
                }

                &-name {
                    font-size: 16px;
                    margin-bottom: 0;
                }

                &-text {
                    vertical-align: top;
                    display: inline-block;
                    padding-bottom: 23px;
                }

                &-line {
                    width: 36px;
                    margin: 0 auto;
                    border-top: 1px solid rgba(0,0,0,.1);
                }

                &-desc {
                    color: #b9b9b9;
                    // width: 265px;
                    width: 100%;
                    margin-top: 10px;
                    text-align: center;

                    .el-button--text {
                        padding: 0;
                    }
                }

                &-link {
                    .el-button {
                        color: $primary;
                        background-color: #fff;
                    }
                }
            }
        }
    }

    &-info {
        padding: 0 10px;
        &-title {
            position: relative;
            padding: 5px 0 5px 10px;
            height: 40px;
            line-height: 30px;
            border-bottom: 1px solid #d3d3d3;

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                width: 4px;
                height: 20px;
                margin-top: -10px;
                background-color: $primary;
            }
        }

        .trend-list {
            padding: 0 10px;
        }

        .order-style {
            padding-top: 30px;

            &-item {
                padding: 0 15px;
                p {
                    color: #333;
                    margin: 0;
                    margin-top: 20px;
                    padding-left: 5px;
                }

                li {
                    display: inline-block;
                    padding: 0 20px;
                    margin: 0 5px 10px;
                    height: 30px;
                    line-height: 30px;
                    font-size: 12px;
                    color: #666;
                    border: 1px solid #d3d3d3;
                    border-radius: 30px;
                }
            }
        }

        .brief-intro p {
            padding: 0 20px;
        }

        .new-trend {
            padding: 20px 0;

            &-empty {
                padding: 30px 0;
                width: 100%;
                text-align: center;
                font-size: 16px;
                color: #666;
            }

            .load-more-trend {
                margin: 60px 0;
                height: 28px;
                line-height: 28px;
                text-align: center;
                color: #b9b9b9;
                border: 1px solid #d3d3d3;
                border-radius: 4px;
                cursor: pointer;

                &:active , &:focus, &:hover {
                    color:#666;
                    background-color: #c8c8c8;
                    border-color: #b3b3b3;

                    .font-icon {
                        fill: #666;
                    }
                }

                .font-icon {
                    width:16px;
                    height:16px;
                    fill: #b9b9b9;
                }
            }
        }

        .has-no-more {
            margin: 60px 0 80px;
            height: 28px;
            line-height: 28px;
            text-align: center;
            color: #b9b9b9;
        }
    }
}
</style>