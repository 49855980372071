var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "trend-item" }, [
    _c(
      "div",
      { staticClass: "avatar-wrapper" },
      [
        _c("avatar", {
          staticClass: "user-avatar",
          attrs: {
            size: "mg",
            src: _vm.trend.posterAvatarUrl,
            userId: _vm.trend.posterId,
            enableCard: true,
            enableLink: true,
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "trend-item-right" }, [
      _c("div", { staticClass: "trend-item-info" }, [
        _c("h3", [
          _vm._v(
            "\n                " +
              _vm._s(_vm.trend.posterRealName) +
              "\n                "
          ),
          _c("span", [_vm._v("@" + _vm._s(_vm.trend.posterNickName))]),
        ]),
        _c("p", { staticClass: "trend-item-intro" }, [
          _vm.trend.type == 1
            ? _c("span", { staticClass: "trend-item-action" }, [
                _vm._v("推荐了候选人"),
              ])
            : _vm._e(),
          _vm.trend.type == 2 || _vm.trend.type == 4
            ? _c("span", { staticClass: "trend-item-action" }, [
                _vm._v("接受了候选人"),
              ])
            : _vm._e(),
          _vm.trend.type == 3
            ? _c("span", { staticClass: "trend-item-action" }, [
                _vm._v("拒绝了候选人"),
              ])
            : _vm._e(),
          _vm.trend.type == 5 ||
          _vm.trend.type == 9 ||
          _vm.trend.type == 10 ||
          _vm.trend.type == 17
            ? _c("span", { staticClass: "trend-item-action" }, [
                _vm._v("将候选人"),
              ])
            : _vm._e(),
          _vm.trend.type == 6 || _vm.trend.type == 7
            ? _c("span", { staticClass: "trend-item-action" }, [
                _vm._v("为候选人"),
              ])
            : _vm._e(),
          _vm.trend.type == 8
            ? _c("span", { staticClass: "trend-item-action" }, [
                _vm._v("反馈了候选人"),
              ])
            : _vm._e(),
          _vm.trend.type == 11
            ? _c("span", { staticClass: "trend-item-action" }, [
                _vm._v("淘汰了候选人"),
              ])
            : _vm._e(),
          _vm.trend.type == 12
            ? _c("span", { staticClass: "trend-item-action" }, [
                _vm._v("暂停了"),
              ])
            : _vm._e(),
          _vm.trend.type == 13
            ? _c("span", { staticClass: "trend-item-action" }, [
                _vm._v("完成了"),
              ])
            : _vm._e(),
          _vm.trend.type == 14
            ? _c("span", { staticClass: "trend-item-action" }, [
                _vm._v("重启了"),
              ])
            : _vm._e(),
          _vm.trend.type == 15
            ? _c("span", { staticClass: "trend-item-action" }, [
                _vm._v("发布了"),
              ])
            : _vm._e(),
          _vm.trend.type == 16
            ? _c("span", { staticClass: "trend-item-action" }, [_vm._v("将")])
            : _vm._e(),
          _vm.trend.type == 18
            ? _c("span", { staticClass: "trend-item-action" }, [_vm._v("将")])
            : _vm._e(),
          _vm.trend.type == 19
            ? _c("span", { staticClass: "trend-item-action" }, [
                _vm._v("为职位"),
              ])
            : _vm._e(),
          _vm.trend.type == 20
            ? _c("span", { staticClass: "trend-item-action" }, [
                _vm._v("打赏了"),
              ])
            : _vm._e(),
          _vm.trend.type == 21
            ? _c("span", { staticClass: "trend-item-action" }, [
                _vm._v("广播了"),
              ])
            : _vm._e(),
          _vm.trend.type == 22 || _vm.trend.type == 23
            ? _c("span", { staticClass: "trend-item-action" }, [
                _vm._v("将职位"),
              ])
            : _vm._e(),
          _vm.trend.type >= 29 && _vm.trend.type <= 33
            ? _c("span", { staticClass: "trend-item-action" }, [
                _vm._v("操作了候选人"),
              ])
            : _vm._e(),
          _vm.trend.type == 19 || _vm.trend.type == 20 || _vm.trend.type == 21
            ? _c("span", [
                _vm._v(
                  _vm._s(
                    (_vm.trend.extensionProperties.amount * 100).toFixed(0)
                  ) + "元"
                ),
              ])
            : _vm._e(),
          _vm.trend.type == 20
            ? _c("span", { staticClass: "trend-item-user-name" }, [
                _vm._v(
                  _vm._s(_vm.trend.extensionProperties.recommenderRealName)
                ),
                _c("i", [
                  _vm._v(
                    "@" +
                      _vm._s(_vm.trend.extensionProperties.recommenderNickname)
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.trend.type != 12 &&
          _vm.trend.type != 13 &&
          _vm.trend.type != 14 &&
          _vm.trend.type != 15 &&
          _vm.trend.type != 16 &&
          _vm.trend.type != 19 &&
          _vm.trend.type != 20 &&
          _vm.trend.type != 21 &&
          _vm.trend.type != 22 &&
          _vm.trend.type != 23
            ? _c(
                "a",
                {
                  staticClass: "trend-item-candidate",
                  attrs: { target: "_blank" },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.trend.extensionProperties.candidateName) +
                      "\n                "
                  ),
                ]
              )
            : _vm._e(),
          _vm.trend.type == 5
            ? _c("span", { staticClass: "trend-item-action" }, [
                _vm._v("推进到面试阶段"),
              ])
            : _vm._e(),
          _vm.trend.type == 6
            ? _c("span", { staticClass: "trend-item-action" }, [
                _vm._v(
                  "安排了" + _vm._s(_vm.trend.extensionProperties.interviewType)
                ),
              ])
            : _vm._e(),
          _vm.trend.type == 7
            ? _c("span", { staticClass: "trend-item-action" }, [
                _vm._v(
                  "取消了" + _vm._s(_vm.trend.extensionProperties.interviewType)
                ),
              ])
            : _vm._e(),
          _vm.trend.type == 8
            ? _c("span", { staticClass: "trend-item-action" }, [
                _vm._v(
                  _vm._s(_vm.trend.extensionProperties.interviewType) + "的结果"
                ),
              ])
            : _vm._e(),
          _vm.trend.type == 8
            ? _c("span", { staticClass: "trend-item-action" }, [
                _vm._v(
                  "(" + _vm._s(_vm.trend.extensionProperties.result) + ")"
                ),
              ])
            : _vm._e(),
          _vm.trend.type == 9
            ? _c("span", { staticClass: "trend-item-action" }, [
                _vm._v("推进到Offer阶段"),
              ])
            : _vm._e(),
          _vm.trend.type == 10
            ? _c("span", { staticClass: "trend-item-action" }, [
                _vm._v("推进到入职阶段"),
              ])
            : _vm._e(),
          _vm.trend.type == 16
            ? _c("span", { staticClass: "trend-item-action" }, [
                _vm._v("移交给"),
              ])
            : _vm._e(),
          _vm.trend.type == 17
            ? _c("span", { staticClass: "trend-item-action" }, [
                _vm._v("筛选通过了候选人"),
              ])
            : _vm._e(),
          _vm.trend.type == 19
            ? _c("span", { staticClass: "trend-item-action" }, [
                _vm._v("充值红包"),
              ])
            : _vm._e(),
          _vm.trend.type == 22
            ? _c("span", { staticClass: "trend-item-action" }, [
                _vm._v("的状态修改为不紧急"),
              ])
            : _vm._e(),
          _vm.trend.type == 23
            ? _c("span", { staticClass: "trend-item-action" }, [
                _vm._v("的状态修改为紧急"),
              ])
            : _vm._e(),
          _vm.trend.type <= 23
            ? _c("span", { staticClass: "trend-item-job" }, [
                _vm.trend.extensionProperties.jobDetailUrl ||
                _vm.trend.extensionProperties.jobUrl
                  ? _c(
                      "a",
                      {
                        attrs: {
                          href:
                            _vm.trend.extensionProperties.jobDetailUrl ||
                            _vm.trend.extensionProperties.jobUrl,
                          target: "_blank",
                        },
                      },
                      [_vm._v(_vm._s(_vm.trend.extensionProperties.jobName))]
                    )
                  : _c("span", [
                      _vm._v(_vm._s(_vm.trend.extensionProperties.jobName)),
                    ]),
                _vm._v("\n                     \n                    "),
                _c("i", [
                  _vm._v(
                    "[ " +
                      _vm._s(_vm.trend.extensionProperties.jobCompany) +
                      "-" +
                      _vm._s(_vm.trend.extensionProperties.jobArea) +
                      " ]"
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.trend.type == 16
            ? _c("span", { staticClass: "trend-item-anchor" }, [
                _vm._v(_vm._s(_vm.trend.extensionProperties.to)),
              ])
            : _vm._e(),
        ]),
        _vm.trend.type == 3 &&
        (_vm.trend.extensionProperties.rejectedDetail ||
          _vm.trendextensionProperties.rejectedReason)
          ? _c("p", { staticClass: "trend-item-extra" }, [
              _vm.trend.extensionProperties.rejectedReason
                ? _c("span", [
                    _vm._v(
                      "原因: " +
                        _vm._s(_vm.trend.extensionProperties.rejectedReason)
                    ),
                  ])
                : _vm._e(),
              _vm.trend.extensionProperties.rejectedReason &&
              _vm.trend.extensionProperties.rejectedDetail
                ? _c("br")
                : _vm._e(),
              _vm.extensionProperties.rejectedDetail
                ? _c("span", [
                    _vm._v(
                      "明细: " +
                        _vm._s(_vm.trend.extensionProperties.rejectedDetail)
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm.trend.type == 8 && _vm.trend.extensionProperties.evaluation
          ? _c("p", { staticClass: "dynamic-item-extra" }, [
              _vm._v(
                "评价：" + _vm._s(_vm.trend.extensionProperties.evaluation)
              ),
            ])
          : _vm._e(),
        _vm.trend.type == 11 && _vm.trend.extensionProperties.obsoletedReason
          ? _c(
              "p",
              { staticClass: "dynamic-item-extra" },
              [
                _c("span", [
                  _vm._v(
                    "原因：" +
                      _vm._s(_vm.trend.extensionProperties.obsoletedReason)
                  ),
                ]),
                _vm.trend.extensionProperties.obsoletedDetail
                  ? _c(
                      "el-tooltip",
                      {
                        attrs: {
                          effect: "light",
                          content:
                            _vm.trend.extensionProperties.obsoletedDetail,
                          placement: "right",
                        },
                      },
                      [_c("i", { staticClass: "fa fa-commenting-o" })]
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm.trend.type == 12 && _vm.trend.extensionProperties.reason
          ? _c("p", { staticClass: "dynamic-item-extra" }, [
              _vm._v(_vm._s(_vm.trend.extensionProperties.reason)),
            ])
          : _vm._e(),
        _vm.trend.type == 20 && _vm.trend.extensionProperties.remark
          ? _c("p", { staticClass: "dynamic-item-extra" }, [
              _vm._v(_vm._s(_vm.trend.extensionProperties.remark)),
            ])
          : _vm._e(),
        _vm.trend.type == 21 && _vm.trend.extensionProperties.content
          ? _c("p", { staticClass: "dynamic-item-extra" }, [
              _vm._v(_vm._s(_vm.trend.extensionProperties.content)),
            ])
          : _vm._e(),
      ]),
    ]),
    _c("div", { staticClass: "trend-item-time" }, [
      _vm._v(_vm._s(_vm._f("datetime")(_vm.trend.createdString))),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }